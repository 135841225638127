import parse, {
  DOMNode,
  Element,
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from 'html-react-parser';
import {Fragment, createElement, useMemo} from 'react';

type ChildNode = Element['childNodes'][number];

type CDataNode = Extract<ChildNode, {type: 'cdata'}>;

type TextNode = Extract<ChildNode, {type: 'text'}>;

/**
 * Checks if the given `domNode` is a `<script>` element.
 * @param domNode to check for `tagName`
 * @returns `true` iff `domNode` is `<script>` element.
 */
function isScriptElement(domNode: DOMNode): domNode is Element {
  return (
    domNode instanceof Element &&
    domNode.tagName.toLowerCase() === 'script' &&
    domNode.type === 'script'
  );
}

interface UseParsedTextOptions {
  /**
   * Whether or not parsing should remove `<script>` tags
   * @default false
   */
  allowScripts?: boolean;
  /**
   * Attributes to remove _from all tags_. By default is remove data attributes
   * associated with copy/paste from Figma.
   * @default ['data-buffer', 'data-metadata']
   */
  attrsToRemove?: string[];
}

/**
 * Runs `content` through `parse` from `html-react-parser`.
 * replaces `<script>` tags. @see {@link isScriptElement}
 * @param content plain text or `html` markup.
 * @param options can be used to allow script tags
 * @returns parsed text via `html-react-parser`.
 */
export const useParsedText = (
  content: string,
  options?: UseParsedTextOptions
): string | JSX.Element | JSX.Element[] => {
  const {
    allowScripts = false,
    attrsToRemove = ['data-buffer', 'data-metadata'],
  } = options ?? {};
  return useMemo(() => {
    const parseOptions: HTMLReactParserOptions = {
      replace: (domNode) => {
        if (isScriptElement(domNode) && !allowScripts) {
          return <Fragment />;
        } else if (domNode instanceof Element) {
          if (
            isScriptElement(domNode) &&
            allowScripts &&
            typeof document !== 'undefined'
          ) {
            // Create the node in the document because while html-react-parser
            // does parse the <script> tags they do not get rendered by
            // `react-dom`. To trigger the scripts included they must be injected
            // into the DOM.
            // See https://github.com/remarkablemark/html-react-parser/issues/98
            const script = document.createElement('script');
            for (const [key, value] of Object.entries(domNode.attribs)) {
              script.setAttribute(key, value);
            }
            const cdataChildren = domNode.childNodes
              .filter((node): node is CDataNode => node.type === 'cdata')
              .flatMap((node) => node.childNodes);
            const textChildren = domNode.childNodes
              .concat(cdataChildren)
              .filter((node): node is TextNode => node.type === 'text');
            const textContent = textChildren
              .map((node) => node.data)
              .join('\n');
            script.textContent = textContent;
            document.head.appendChild(script);
          } else if (attrsToRemove.some((name) => name in domNode.attribs)) {
            const attributes = Object.assign({}, domNode.attribs);
            for (const name of attrsToRemove) {
              delete attributes[name];
            }
            const props = attributesToProps(attributes);
            return createElement(
              domNode.tagName,
              props,
              domToReact(domNode.children, parseOptions)
            );
          }
        }
      },
    };
    return parse(content, parseOptions);
  }, [allowScripts, attrsToRemove, content]);
};
